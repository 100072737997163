/*
██████╗ ███████╗ ██████╗███████╗███╗   ██╗████████╗
██╔══██╗██╔════╝██╔════╝██╔════╝████╗  ██║╚══██╔══╝
██████╔╝█████╗  ██║     █████╗  ██╔██╗ ██║   ██║   
██╔══██╗██╔══╝  ██║     ██╔══╝  ██║╚██╗██║   ██║   
██║  ██║███████╗╚██████╗███████╗██║ ╚████║   ██║   
╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
 █████╗ ██████╗ ████████╗██╗ ██████╗██╗     ███████╗███████╗
██╔══██╗██╔══██╗╚══██╔══╝██║██╔════╝██║     ██╔════╝██╔════╝
███████║██████╔╝   ██║   ██║██║     ██║     █████╗  ███████╗
██╔══██║██╔══██╗   ██║   ██║██║     ██║     ██╔══╝  ╚════██║
██║  ██║██║  ██║   ██║   ██║╚██████╗███████╗███████╗███████║
╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝╚══════╝╚══════╝╚══════╝
*/
// NOTE: Renders the recent articles block from the page builder
import 'swiper/css'

import ArticleCard from '@components/ArticleCard'
import { useAppContext } from '@hooks/useAppContext'
import { useEffect, useState } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './RecentArticles.module.scss'

const RecentArticles = ({ title, data, type, article }) => {
  const [posts, setPosts] = useState('')
  const { globals } = useAppContext()
  const { site } = globals || {}
  const { recents } = site || {}
  const { articles } = recents || {}
  const { theme, description } = data || {}

  const getPosts = () => {
    const listPosts = []
    articles.map((article, i) => {
      if (!article) return null
      listPosts.push(
        <SwiperSlide key={i}>
          <ArticleCard content={article} ver="recent" theme={theme} />
        </SwiperSlide>
      )
    })
    setPosts(listPosts)
  }

  useEffect(() => {
    if (!posts) {
      getPosts()
    }
  }, [])

  return (
    <div
      className={`${styles.wrapper} ${theme ? styles[theme] : styles.dark}`}
      data-theme={theme ? theme : 'dark'}
    >
      <section className={styles.container}>
        {title && <h2>{title}</h2>}
        {description && <p className={styles.desc}>{description}</p>}
        {article && (
          <p className={styles.desc}>
            Have a look over more posts related to this one
          </p>
        )}

        <Swiper
          className={styles.swiperContainer}
          slidesPerView={1.5}
          modules={[Autoplay]}
          autoplay={{ delay: 2750 }}
          speed={1500}
          spaceBetween={40}
          loop={true}
          lazy={'true'}
          freeMode={true}
          centeredSlides={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              centeredSlides: false,
              spaceBetween: 60,
            },
            1024: {
              slidesPerView: 3,
              centeredSlides: true,
              spaceBetween: 60,
            },
            1480: {
              slidesPerView: 4,
              centeredSlides: false,
              spaceBetween: 60,
            },
          }}
        >
          {type == 'recent'
            ? data.map((article, i) => {
                if (!article) return null
                return (
                  <SwiperSlide key={i}>
                    <ArticleCard content={article} ver="recent" theme={theme} />
                  </SwiperSlide>
                )
              })
            : posts}
        </Swiper>
      </section>
    </div>
  )
}

export default RecentArticles
