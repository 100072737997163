import { useAppContext } from '@hooks/useAppContext'
import { urlFor } from '@lib/sanity'
import { usePageType } from '@state/store'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export default function Seo({
  title,
  defaultTitle,
  description,
  pathname,
  image,
  deIndex,
  children,
  canonical,
  schemaData,
}) {
  const page = usePageType()
  const pageType = page.getPageType()
  const dynamicRoute = useRouter().asPath

  const { globals } = useAppContext()
  const { site } = globals
  const { seo: defaultSeo } = site

  const [data, setData] = useState(schemaData)

  useEffect(() => {
    setData(schemaData)
  }, [dynamicRoute])

  //  This is a helper function that prevents the super annoying seo undefined error that also breaks in production
  const isArticle = () => {
    if (dynamicRoute.includes('/insights/')) {
      return {
        title: data && data.seoContent?.seoTitle,
        description: data && data.seoContent?.seoDescription,
        image: data && data.seoContent?.seoImage,
        author: data && data.author && data.author[0].authorName,
        published: data && data.publishedAt,
        updated: data && data._updatedAt,
      }
    } else {
      return {}
    }
  }

  const seo = {
    title: title || defaultTitle,
    url: `${defaultSeo.url}${pathname || ``}`,
    logo: defaultSeo.logo,
    description: description || defaultSeo.description,
    image: image ? image : defaultSeo.image,
    article: isArticle(),
  }

  // console.log('SEO', seo)

  return (
    <Head>
      <title>{`${title} ― Karmoon`}</title>
      {deIndex && <meta name="robots" content="noindex" />}

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={urlFor(seo.image).url()} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={`https://karmoon.co.uk` + dynamicRoute} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={urlFor(seo.image).url()} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.title} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@wearekarmoon" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={urlFor(seo.image).url()} />
      <meta name="twitter:image:alt" content={seo.title} />
      {/* <meta name="twitter:creator" content={author} /> */}

      <link rel="preconnect" href="https://cdn.sanity.io" />
      <link rel="dns-prefetch" href="https://cdn.sanity.io" />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png`"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />

      <link
        rel="sitemap"
        type="application/xml"
        title="Sitemap"
        href="/sitemap.xml"
      />

      {children}

      {canonical && <link rel="canonical" href={canonical} />}

      <script 
      type="application/ld+json" 
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(getLogoStructuredData(seo)),
     }}
      />

      <script 
      type="application/ld+json" 
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(getLocalBusinessStructuredData()),
     }}
      />
    
      {pageType === 'article' && (
              <script 
              type="application/ld+json" 
              dangerouslySetInnerHTML={{
             __html: JSON.stringify(createSchemaMarkup(seo, pageType)),
         }}
          />
      )}

      <link
        rel="preload"
        href="/fonts/Inter.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/NaNJauneTRIAL-MidiBlack.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/NaNJauneTRIAL-MidiBold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/NaNJauneTRIAL-MidiMedium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Head>
  )
}

const createSchemaMarkup = (data) => {
  const { asPath } = useRouter()
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : ''

  if (!data) return null

  const siteLogo = urlFor(data && data.logo.asset).url()

  const articleImage = data.article.image
    ? urlFor(data && data.article.image.asset)
        .crop('focalpoint')
        .fit('crop')
        .quality(75)
        .width(700)
        .sharpen(12)
        .dpr(1)
        .format('webp')
        .url()
    : null

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${origin + asPath}`,
    },
    headline: `${data.article.title}`,
    image: {
      '@type': 'ImageObject',
      url: `${articleImage}`,
      height: 463,
      width: 700,
    },
    datePublished: `${data.article.published}`,
    dateModified: `${data.article.updated}`,
    author: {
      '@type': 'Person',
      name: `${data.article.author ? data.article.author.name : ''}`,
      url: `${origin}`,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Karmoon',
      logo: {
        '@type': 'ImageObject',
        url: `${siteLogo}`,
        width: 400,
        height: 400,
      },
    },
    description: `${data.article.description}`,
  }

  return schema
}

const getLogoStructuredData = (data) => {
  const siteLogo = urlFor(data.logo.asset).url()

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://karmoon.co.uk/',
    logo: `${siteLogo}`,
  }

  return schema
}

const getLocalBusinessStructuredData = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ProfessionalService',
    name: 'Karmoon',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Remote',
      addressLocality: 'Remote',
      addressRegion: 'Remote',
      postalCode: 'Remote',
      addressCountry: 'UK',
    },
    review: {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
        bestRating: '5',
      },
      author: {
        '@type': 'Person',
        name: 'Rick Sanchez',
      },
    },
    url: 'https://karmoon.co.uk/',
    telephone: '+447468 486 568',
    email: 'hello@karmoon.co.uk',
    founder: 'Louie Babb',
    brand: 'Shopify',
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '9:00',
        closes: '18:00',
      },
    ],
  }
}
