/* eslint-disable no-case-declarations */
export const getDate = (_date, type) => {
  let date = new Date(_date)

  let options = ''
  let dayOptions = ''
  let monthOptions = ''

  switch (type) {
    case 'long':
      dayOptions = {
        day: 'numeric',
      }

      monthOptions = {
        month: 'long',
      }

      const day = date.toLocaleDateString('en-GB', dayOptions)
      const month = date.toLocaleDateString('en-GB', monthOptions)
      const year = date.getFullYear()
      const ordinal = nth(day)

      const cleanDate = `${day}${ordinal} ${month} ${year}`

      return cleanDate

    default:
      options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }

      return date.toLocaleDateString('en-GB', options)
  }
}

function nth(d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
