import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'

import { getDate } from '../utils/formatDate'
import styles from './ArticleCard.module.scss'

export default function ArticleCard({ content, ver, theme }) {
  const { title, slug, image, publishedAt: pubDate, times } = content
  const _time = times.estimatedReadingTime + ' Minute Read'
  let d = getDate(pubDate)

  switch (ver) {
    case 'recent':
      return (
        <div className={`${styles.wrapper} ${styles[theme]}`}>
            {image && (
              <Link href={`/insights/${slug}`} title={title} prefetch={false}>
                <div className={styles.imageWrapper}>
                  <Image
                    src={urlFor(image.asset)
                      .crop('focalpoint')
                      .fit('crop')
                      .quality(97)
                      .width(500)
                      .height(500)
                      .sharpen(12)
                      .dpr(1)
                      .format('webp')
                      .url()}
                    alt={title}
                    fill
                    sizes={
                      '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
                    }
                  />
                </div>
              </Link>
            )}
            <div className={`${styles.details} ${styles[theme]}`}>
                <span className={styles.date}>{d}</span>
                <span className={styles.time}>{_time}</span>
              <h3 className={styles.title}>
                <Link href={`/insights/${slug}`} title={title} prefetch={false}>
                  {title}
                </Link>
              </h3>
            </div>
        </div>
      )

    case 'result':
      return (
        <div className={styles.wrapper}>
          {content.mainImage && (
            <Link href={`/insights/${slug.current}`} title={title} prefetch={false}>
              <Image
                src={urlFor(content.mainImage.asset)
                  .crop('focalpoint')
                  .fit('crop')
                  .quality(75)
                  .width(700)
                  .sharpen(12)
                  .dpr(1)
                  .format('webp')
                  .url()}
                alt={title}
                fill
                sizes={
                  '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
                }
              />
            </Link>
          )}
          <div className={styles.details}>
              <span className={styles.date}>{content.publishedAt}</span>
              <span className={styles.time}>{_time}</span>
            <h3 className={styles.title}>
              <Link href={`/insights/${slug.current}`} title={title} prefetch={false}>
                {title}
              </Link>
            </h3>
          </div>
        </div>
      )
  }
}
